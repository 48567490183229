<template>
	<Metier name="maintenance-courante" title="Maintenance courante" :main-menu="[{title: 'Contremaître maintenance courante', missions: ['Participe à l\'élaboration et à la validation des plans de maintenance préventive de son secteur', 'Pilote les opérations de mise à disposition et de remise en service des unités de son secteur', 'Participe à l\'amélioration des processus de maintenance sur son secteur en collaboration avec le service Méthodes Générales', 'Veille à la sécurité et au respect de l\'environnement sur les missions de maintenance dont il a la charge'], competences: ['Formation technique', 'Grande connaissance des exigences de maintenance en milieu pétrolier', 'Connaissance de la législation et des règles de sécurité, des procédures administratives', 'Connaissance des équipements et des procédés'], qualites: ['Leadership', 'Rigueur', 'Organisation', 'Sang-Froid']}, {title: 'Agent Technique de zone Maintenance Courante', missions: ['Préparer les plans de prévention et les procès-verbaux de lancement des travaux du service', 'Etablir les cahiers des charges pour les appels d\'offre et suivre l\'activité des entreprises', 'Participer à la démarche disponibilité et maitrise des coûts de la raffinerie'], competences: ['Formation en maintenance industrielle', 'Connaissance du milieu pétrolier'], qualites: ['Rigueur', 'Polyvalence', 'Leadership']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
